import React, { useCallback, useEffect, useRef, useState } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { Checkbox, message } from "antd";
import useContainerDimensions from "../../use-container-dimensions/Index";
import { extractNumberFromString, useMouseTrap } from "../../../../utils";
import { nanoid } from "nanoid";
import { debounce } from "lodash";
import XLSX from "sheetjs-style";
import { saveAs } from "file-saver";

const checkIfCellIsFocused = (rowIndex, colId, id) => {
  const activeElement = document.activeElement;
  const isFocused = activeElement.parentNode.getAttribute("row-index") == rowIndex
    && activeElement.getAttribute("col-id") === colId
    && (!id || activeElement.parentElement.getAttribute("row-id") == id);
  return isFocused;
}

const ensureCellIsFocused = (gridApiRef, rowIndex, colId, id, containerRef) => {
  if (!gridApiRef?.current?.rowModel?.rowsToDisplay?.length) {
    containerRef?.current?.focus?.();
    return
  }
  gridApiRef?.current.ensureIndexVisible(rowIndex, 'middle');
  gridApiRef?.current.setFocusedCell(rowIndex, colId);
  const isCellFocused = checkIfCellIsFocused(rowIndex, colId, id);
  if (!isCellFocused) {
    let maxRetries = 15;
    const timer = setInterval(() => {
      const isCellFocused = checkIfCellIsFocused(rowIndex, colId, id);
      const filteredRows = gridApiRef?.current?.rowModel?.rowsToDisplay?.length;
      const cellExists = rowIndex < filteredRows;
      if (isCellFocused || maxRetries <= 0 || !cellExists) {
        clearInterval(timer);
      } else {
        gridApiRef?.current.ensureIndexVisible(rowIndex, 'middle');
        gridApiRef?.current.setFocusedCell(rowIndex, colId);
        maxRetries--;
      }
    }, 25);
  }
}

const getAutoSizeableColumnIds = (columns) => {
  const colIds = [];
  columns.forEach((col) => {
    if (col.children) {
      colIds.push(...getAutoSizeableColumnIds(col.children));
    }
    if (col.autoSize) {
      colIds.push(col.id);
    }
  });
  return colIds;
};

const autoSizeColumns = (colApi, columns, gridApi, gridId) => {
  const autoSizeableColumnIds = getAutoSizeableColumnIds(columns);
  colApi.autoSizeColumns(autoSizeableColumnIds);
  const gridBodyWidth = document.getElementsByClassName(`grid${gridId}`)[0].clientWidth;
  const allColumnsWidth = colApi
    .getAllDisplayedColumns()
    .map((c) => c.getActualWidth())
    .reduce((a, b) => a + b, 0);
  if (gridBodyWidth > allColumnsWidth) {
    gridApi.sizeColumnsToFit();
  }
};

const getGridFirstNavigableColId = (columns) => {
  let colId = undefined;
  columns.forEach((col) => {
    if (!colId) {
      if (col.children) {
        colId = getGridFirstNavigableColId(col.children);
        return;
      }
      if (!col.suppressNavigable) {
        colId = col.field || col.id;
      }
    }
  });
  return colId;
};
const getGridNavigableColIdWithPreference = (gridApi, preferrableColId) => {
  if (!gridApi) {
    return;
  }

  if (preferrableColId) {
    const preferrableColDef = gridApi.columnModel.columnDefs.find(
      (colDef) => colDef.field === preferrableColId
    );
    if (preferrableColDef && !preferrableColDef.suppressNavigable) {
      return preferrableColId;
    }
  }

  return getGridFirstNavigableColId(gridApi.columnModel.columnDefs);
};

export function useMultipleAgGrids() {
  const gridApisRef = useRef([]);
  const cellFocusedCallbacksRef = useRef([]);
  const cellKeyDownCallbacksRef = useRef([]);
  const [focusedGridIndex, setFocusedGridIndex] = useState(-1);

  function setGridApi(gridIndex, gridApi) {
    gridApisRef.current[gridIndex] = gridApi;
    function onCellFocused(e) {
      if (e.rowIndex === null || e.rowIndex === undefined) {
        return;
      }
      if (focusedGridIndex !== gridIndex) {
        setFocusedGridIndex(gridIndex);
      }
      gridApisRef.current
        .filter((_, index) => index !== gridIndex)
        .forEach((api) => {
          if (api.getFocusedCell()) {
            api.clearFocusedCell();
          }
        });
    }

    function onCellKeyDown(e) {
      const keyPressed = e.event.key;
      const columnId = e.colDef.field;
      // Navigate to next grid
      if (keyPressed === "ArrowDown") {
        const rowCount = e.api.rowModel.rowsToDisplay.length;
        const isLastRow = e.rowIndex === rowCount - 1;
        if (isLastRow) {
          const nextGridApi = gridApisRef.current.find(
            (api, i) => i > gridIndex && api.rowModel.rowsToDisplay.length > 0
          );
          if (nextGridApi) {
            const nextGridNavigableColId = getGridNavigableColIdWithPreference(
              nextGridApi,
              columnId
            );
            nextGridApi.setFocusedCell(0, nextGridNavigableColId);
          }
        }
      }
      // Navigate to prev grid
      if (keyPressed === "ArrowUp") {
        const isFirstRow = e.rowIndex === 0;
        if (isFirstRow && gridIndex > 0) {
          const prevGridApi = gridApisRef.current
            .map((api, i) => ({ api, i }))
            .reverse()
            .find(({ api, i }) => i < gridIndex && api.rowModel.rowsToDisplay.length > 0)?.api;
          if (prevGridApi) {
            const nextGridNavigableColId = getGridNavigableColIdWithPreference(
              prevGridApi,
              columnId
            );
            prevGridApi.setFocusedCell(
              prevGridApi.rowModel.rowsToDisplay.length - 1,
              nextGridNavigableColId
            );
          }
        }
      }
    }
    gridApi.addEventListener("cellFocused", onCellFocused);
    gridApi.addEventListener("cellKeyDown", onCellKeyDown);
    cellFocusedCallbacksRef.current[gridIndex] = onCellFocused;
    cellKeyDownCallbacksRef.current[gridIndex] = onCellKeyDown;
  }

  function restoreFocus() {
    if (focusedGridIndex !== -1) {
      const gridApi = gridApisRef.current[focusedGridIndex];
      if (gridApi) {
        gridApi.restoreFocus();
      }
    }
  }

  useEffect(() => {
    return () => {
      gridApisRef.current.forEach((gridApi, gridIndex) => {
        gridApi.removeEventListener("cellFocused", cellFocusedCallbacksRef.current[gridIndex]);
        gridApi.removeEventListener("cellKeyDown", cellKeyDownCallbacksRef.current[gridIndex]);
      });
    };
  }, []);

  return [setGridApi, focusedGridIndex, restoreFocus];
}

const mapColumnDefsToAgGridColumnDefs = (columns, enableSearch, onEnter) => {
  return columns.map(
    ({
      id,
      title,
      width,
      autoSize,
      minWidth,
      isNavigable,
      align,
      searchType,
      cellStyle,
      tooltipValueGetter,
      children,
      ...rest
    }) => {
      return (
        (
          <AgGridColumn
            field={id}
            headerName={title}
            width={width}
            minWidth={minWidth}
            flex={!width && !autoSize ? 1 : undefined}
            onCellDoubleClicked={
              children
                ? undefined
                : (e) =>
                  onEnter?.(
                    e.data,
                    e.colDef.field,
                    e.api.getSelectedNodes().map((obj) => obj.data),
                    e.event,
                    e.api,
                    e.node,
                  )
            }
            {...getCellProps(isNavigable, enableSearch, searchType)}
            {...rest}
            cellStyle={cellStyle && typeof cellStyle === 'function' ? cellStyle : {
              textAlign: align,
              ...cellStyle,
            }}
            tooltipField={!children && !tooltipValueGetter ? id : null}
            tooltipValueGetter={tooltipValueGetter}
          >
            {children && mapColumnDefsToAgGridColumnDefs(children, enableSearch, onEnter)}
          </AgGridColumn>
        )
      )
    }
  );
};

const checkIfRefnoMatches = (refno1, refno2) => {
  // if starts with alphabet prefix
  if (/[^$,\.\d]/.test(refno2)) { //eslint-disable-line 
    return refno1?.startsWith(refno2);
  }
  const refno1Parts = !refno1
    ? []
    : refno1.split(/[_//-]/).map((el) => extractNumberFromString(el));
  const refno2Parts = !refno2
    ? []
    : refno2.split(/[_//-]/).map((el) => extractNumberFromString(el));
  if (refno1Parts.length === refno2Parts.length) {
    return refno1Parts.every((refno1Part, i) => refno1Part === refno2Parts[i]);
  }
  return (
    refno1Parts.some((refno1Part) => refno2Parts.some((refno2Part) => refno1Part === refno2Part)) ||
    refno2Parts.some((refno2Part) => refno1Parts.some((refno1Part) => refno1Part === refno2Part))
  );
};

const getCellProps = (isNavigable, searchEnabled, searchType) => {
  const customTextComparator = (_, cellValue, searchValue) => {
    if (searchValue === "*") {
      return cellValue != "-" && cellValue != null;
    } else if (searchValue.startsWith("*")) {
      return String.prototype.includes.call(cellValue, searchValue.slice(1));
    } else if (searchValue.startsWith("!")) {
      return searchValue.length > 1 ? (!String.prototype.includes.call(cellValue, searchValue.slice(1))) : true;
    } else if (searchType === "refno") {
      return checkIfRefnoMatches(cellValue, searchValue)
    } else {
      return cellValue?.startsWith(searchValue);
    }
  };

  const searchableCellProps = {
    filter: true,
    floatingFilter: true,
    filterParams: {
      textCustomComparator: customTextComparator,
    },
    floatingFilterComponentParams: { suppressFilterButton: true },
    suppressMenu: true,
  };

  const nonSearchableCellProps = {
    filter: false,
  };

  const navigableCellProps = {
    sortable: true,
  };
  const nonNavigableCellProps = {
    suppressNavigable: true,
    cellClass: "noFocus",
  };

  return Object.assign(
    {},
    isNavigable ? navigableCellProps : nonNavigableCellProps,
    searchEnabled ? searchableCellProps : nonSearchableCellProps
  );
};

const DataTable = ({
  data,
  columns,
  containerStyle,
  containerClass,
  rowSelection,
  toggleRowSelectionOnTab,
  onEnter,
  onCellKeyDown,
  frameworkComponents,
  showSummary,
  getSummary,
  onSelectionChanged,
  onRowSelected,
  onFocusedRowChanged,
  rowCountColumn,
  enableSearch = true,
  style = {},
  onGridReady,
  autoFocus = true,
  disableRowFocus,
  rowHeight = 34,
  autoSizePadding = 4,
  gridOptions = {},
  forwardedRef,
  title,
  initialRowIndex,
  initialColId,
  initialFilterModel,
  initialSelectedNodes,
  initialSortModel,
  suppressFocus,
  suppressKeys = [],
  onFirstDataRendered,
  onRowDataChanged,
  hideCheckboxForRowSelection,
  fileName = "Report"
}) => {
  const gridApiRef = useRef();
  const focusedRowIdRef = useRef();
  const headerCheckboxRef = useRef();
  const containerRef = forwardedRef ? forwardedRef : useRef();
  const gridIdRef = useRef(nanoid());
  const { width } = useContainerDimensions(containerRef);
  const selectedNodesRef = useRef();
  const searchInputsRef = useRef({});

  const agGridStyle = { ...style };
  if (width && width % 1 !== 0) {
    agGridStyle.width = Math.ceil(width);
  }

  const updateSummary = debounce(() => {
    if (gridApiRef.current && showSummary && getSummary) {
      const selectedRows = gridApiRef.current.getSelectedNodes().map((obj) => obj.data);
      const filteredRows = gridApiRef.current.rowModel.rowsToDisplay.map((obj) => obj.data);

      const filteredRowsSummary = getSummary(filteredRows);
      const pinnedBottomRows = [filteredRowsSummary];
      if (selectedRows.length) {
        const selectedRowsSummary = getSummary(selectedRows);
        pinnedBottomRows.unshift(selectedRowsSummary);
      }

      if (rowCountColumn) {
        pinnedBottomRows.forEach((obj, i) => {
          if (pinnedBottomRows.length == 1 || i == 1) {
            obj[rowCountColumn] = `Total rows (${filteredRows.length})`;
          } else {
            obj[rowCountColumn] = `Selected rows (${selectedRows.length})`;
          }
        });
      }
      gridApiRef.current.setPinnedBottomRowData(pinnedBottomRows);
    }
  }, 50);

  const handleOnSelectionChanged = debounce((e) => {
    selectedNodesRef.current = gridApiRef.current.getSelectedNodes();
    if (onSelectionChanged) {
      onSelectionChanged(e.api.getSelectedNodes().map((obj) => obj.data))
    }
    updateSummary();
  }, 50);

  const handleOnFilterModified = (e) => {
    // On search change the focus to the first row
    const filteredRowsCount = gridApiRef.current.rowModel.rowsToDisplay.length;
    const focusedCell = e.api.getFocusedCell();
    const rowIndex =
      (!focusedCell || focusedCell.rowIndex >= filteredRowsCount) ? 0 : focusedCell.rowIndex;
    ensureCellIsFocused(gridApiRef, rowIndex, e.column.getColId(), gridApiRef.current.rowModel.rowsToDisplay[0]?.id, containerRef);
    searchInputsRef.current[e.column.getColId()] = e.filterInstance?.appliedModel?.filter;
    if (showSummary) {
      updateSummary();
    }
  };

  const selectAllFilteredRows = () => {
    gridApiRef.current.selectAllFiltered();
  };

  const handleChangeHeaderCheckbox = (e) => {
    const { checked } = e.target;
    if (checked) {
      selectAllFilteredRows();
    } else {
      gridApiRef.current.deselectAll();
    }
  };

  function suppressKeyboardEvents(params) {
    const KEY_SPACE = " ";
    const KEY_ARROW_UP = "ArrowUp";
    const KEY_ARROW_DOWN = "ArrowDown";
    const KEY_TAB = "Tab";

    const event = params.event;
    const keyPressed = event.key;
    if (keyPressed === KEY_SPACE && !event.shiftKey && !event.ctrlKey && !params.colDef.editable) {
      event.preventDefault();
      return true;
    }
    if (toggleRowSelectionOnTab && keyPressed === KEY_TAB) {
      event.preventDefault();
      return true;
    }
    if (keyPressed === KEY_ARROW_UP && params.node.rowIndex === 0) {
      return true;
    }
    if (
      keyPressed === KEY_ARROW_DOWN &&
      params.node.rowIndex === params.api.rowModel.rowsToDisplay.length - 1
    ) {
      return true;
    }
    if (suppressKeys.includes(keyPressed)) {
      event.stopImmediatePropagation();
      event.stopPropagation();
      event.preventDefault();
      return true;
    }
  }

  useEffect(() => {
    if (autoFocus && !suppressFocus && !data?.length) {
      if (containerRef.current) {
        containerRef.current.tabIndex = -1;
        containerRef.current.focus?.();
      }
    }
  }, [autoFocus, suppressFocus, data])

  useMouseTrap(containerRef, {
    "ctrl+a": () => {
      if (!rowSelection) {
        return;
      }
      const selectedRowsCount = gridApiRef.current.getSelectedNodes().length;
      if (selectedRowsCount === 0) {
        selectAllFilteredRows();
      } else {
        gridApiRef.current.deselectAll();
      }
    },
    "ctrl+shift+home": () => {
      gridApiRef.current.deselectAll();
    },
    "ctrl+e": () => {
      const columnsToExport = columns.filter(obj => obj.id);
      let rowNodes = gridApiRef.current.rowModel.rowsToDisplay;
      const selectedRowNodes = gridApiRef.current.getSelectedNodes();
      if (selectedRowNodes.length > 0) {
        rowNodes = selectedRowNodes;
      }
      const rows = rowNodes
        .map(row => columnsToExport.map(col => {
          return ({ [col.title]: gridApiRef.current.getValue(col.id, row) })
        }).reduce((acc, curr) => ({ ...acc, ...curr }), {})
        )
      const summaryRow = gridApiRef.current.getPinnedBottomRow(0);
      rows.push(
        columnsToExport.map(col => {
          return ({ [col.title]: gridApiRef.current.getValue(col.id, summaryRow) })
        }).reduce((acc, curr) => ({ ...acc, ...curr }), {})
      );

      const ws = XLSX.utils.json_to_sheet(rows, {});
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const buffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
      const data = new Blob([buffer], { type: "application/vnd.ms-excel" })
      saveAs(data, fileName + ".xlsx");
    }
  });

  useMouseTrap(
    { current: document.body },
    {
      backspace: (e) => {
        const api = gridApiRef.current;
        if (api) {
          const columnId = api.getFocusedCell()?.column?.colId;
          const displayedRowsCount = api.rowModel?.rowsToDisplay?.length;
          if (displayedRowsCount === 0 && columnId) {
            const filterInstance = api.getFilterInstance(columnId);
            if (filterInstance) {
              const currValue = String(filterInstance.getModel()?.filter ?? "");
              if (currValue) {
                const newValue = currValue.slice(0, -1);
                const newFilterModel = {
                  filterType: "text",
                  type: "contains",
                  filter: newValue,
                };
                e.preventDefault();
                e.stopImmediatePropagation();
                filterInstance.setModel(newFilterModel);
                api.onFilterChanged();
              }
            }
          }
        }
      },
      del: () => {
        const api = gridApiRef.current;
        if (api) {
          const columnId = api.getFocusedCell()?.column?.colId;
          const displayedRowsCount = api.rowModel?.rowsToDisplay?.length;
          if (displayedRowsCount === 0 && columnId) {
            const filterInstance = api.getFilterInstance(columnId);
            if (filterInstance) {
              const newFilterModel = {
                filterType: "text",
                type: "contains",
                filter: "",
              };
              filterInstance.setModel(newFilterModel);
              api.onFilterChanged();
            }
          }
        }
      },
    }
  );

  const autoSizeColumnsIfNeeded = useCallback(
    debounce((e) => {
      const showEllipsis = Array.from(
        document.querySelectorAll(`.grid${gridIdRef.current} .ag-cell`)
      ).some((el) => {
        return el.scrollWidth > el.clientWidth;
      });
      if (showEllipsis) {
        autoSizeColumns(e.columnApi, columns, gridApiRef.current, gridIdRef.current);
      }
    }, 1000),
    []
  );

  // We have moved the logic of setting filters on typing from onCellKeydown to here
  // since there it was missing keystrokes on fast typing
  useEffect(() => {
    function handleKeydown(event) {
      // Note: This setTimeout is necessary since the activeElement changes to body for a while on typing
      setTimeout(() => {
        if (containerRef?.current?.contains(document.activeElement) && document.activeElement.nodeName !== "INPUT") {
          const keyPressed = event.key;
          const api = gridApiRef.current;
          const focusedCell = api?.getFocusedCell();
          const colDef = focusedCell?.column?.colDef;
          const columnId = colDef?.field;
          const isColumnEditable = colDef?.editable;
          if (enableSearch && !isColumnEditable && !suppressKeys.includes(keyPressed)) {
            if (
              (keyPressed === "Backspace" || keyPressed === "Delete" || keyPressed.length === 1) &&
              !event.ctrlKey &&
              !event.altKey
            ) {
              if (keyPressed === "Delete") {
                // NOTE: Calling filterInstance.setModel brings that column to focus
                // Hence we are sorting the columns such that the current focused column should come last
                const currFocusColId = api.getFocusedCell().column.getColId();
                columns
                  .slice()
                  .sort((a) => (a.id === currFocusColId ? 1 : -1))
                  .forEach((col) => {
                    if (col.id) {
                      const filterInstance = api.getFilterInstance(col.id);
                      if (filterInstance) {
                        const currValue = String(filterInstance.getModel()?.filter ?? "");
                        if (currValue) {
                          const newFilterModel = {
                            filterType: "text",
                            type: "startsWith",
                            filter: "",
                          };
                          filterInstance.setModel(newFilterModel);
                        }
                      }
                    }
                  });
                api.onFilterChanged();
              } else {
                const filterInstance = api.getFilterInstance(columnId);
                if (filterInstance) {
                  const currValue = String(filterInstance.getModel()?.filter ?? "");
                  let newValue = "";
                  if (keyPressed === "Backspace") {
                    newValue = currValue ? currValue.slice(0, -1) : currValue;
                  } else {
                    newValue = currValue + keyPressed;
                  }
                  const newFilterModel = {
                    filterType: "text",
                    type: "contains",
                    filter: newValue,
                  };
                  filterInstance.setModel(newFilterModel);
                  api.onFilterChanged();
                }
              }
            }
          }
        }
      }, 10)
    }
    document.addEventListener("keydown", handleKeydown)
    return () => {
      document.removeEventListener("keydown", handleKeydown);
    }
  }, [])

  return (
    <div
      tabIndex={disableRowFocus ? undefined : 0}
      className={`ag-theme-balham ${disableRowFocus ? "no-focus" : ""
        } ag-container ${containerClass}`}
      style={containerStyle}
      ref={containerRef}
    >
      {title}
      <AgGridReact
        singleClickEdit
        onBodyScrollEnd={autoSizeColumnsIfNeeded}
        className={`grid${gridIdRef.current}`}
        autoSizePadding={autoSizePadding}
        containerStyle={agGridStyle}
        animateRows={false}
        suppressAnimationFrame
        suppressColumnVirtualisation={true}
        onCellFocused={
          onFocusedRowChanged
            ? (e) => {
              const rowNode = gridApiRef.current?.getDisplayedRowAtIndex(e.rowIndex);
              const rowId = rowNode?.id;
              if (rowId !== focusedRowIdRef.current) {
                onFocusedRowChanged(rowNode?.data);
              }
              focusedRowIdRef.current = rowId;
            }
            : undefined
        }
        enableBrowserTooltips
        rowHeight={rowHeight}
        frameworkComponents={frameworkComponents}
        rowSelection={rowSelection}
        suppressKeyboardEvent={suppressKeyboardEvents}
        onSelectionChanged={handleOnSelectionChanged}
        suppressRowClickSelection
        onNewColumnsLoaded={(e) => autoSizeColumns(e.columnApi, columns, e.api, gridIdRef.current)}
        onGridReady={(e) => {
          gridApiRef.current = e.api;
          onGridReady?.(e.api);
          e.api.restoreFocus = () => {
            const focusedCell = e.api.getFocusedCell();
            if (focusedCell) {
              ensureCellIsFocused(gridApiRef, focusedCell.rowIndex, focusedCell.column.colId);
            }
          };
          e.api.updateSummary = updateSummary;
          autoSizeColumns(e.columnApi, columns, gridApiRef.current, gridIdRef.current);
        }}
        onFirstDataRendered={(e) => {
          if (initialFilterModel) {
            gridApiRef.current.setFilterModel(initialFilterModel);
          }
          if (initialSortModel) {
            e.columnApi.applyColumnState({ state: [{ colId: initialSortModel.colId, sort: initialSortModel.sort }] })
          }
          if (initialSelectedNodes?.length) {
            initialSelectedNodes.forEach(node => gridApiRef.current.getRowNode(node.id).setSelected(true));
          }
          if (autoFocus) {
            const rowIndex = initialRowIndex || 0;
            const colId = initialColId || getGridFirstNavigableColId(columns);
            ensureCellIsFocused(gridApiRef, rowIndex, colId);
          }
          if (showSummary) {
            updateSummary();
          }
          autoSizeColumns(e.columnApi, columns, gridApiRef.current, gridIdRef.current);
          onFirstDataRendered?.();
        }}
        onRowDataChanged={(e) => {
          if (!suppressFocus) {
            const focusedCell = gridApiRef.current?.getFocusedCell();
            const totalRows = gridApiRef.current?.rowModel.rowsToDisplay.length;
            if (focusedCell) {
              let focusedRowIndex = focusedCell?.rowIndex;
              let focusedColId = focusedCell?.column.colId;
              if (focusedRowIndex >= totalRows && totalRows > 0) {
                focusedRowIndex = totalRows - 1;
              }
              setTimeout(() => {
                if (autoFocus) {
                  ensureCellIsFocused(gridApiRef, focusedRowIndex, focusedColId, null, containerRef);
                }
                if (onFocusedRowChanged) {
                  onFocusedRowChanged(gridApiRef.current?.getDisplayedRowAtIndex(focusedRowIndex)?.data);
                }
              }, 100);
            }
            if (showSummary) {
              updateSummary();
            }
          }
          selectedNodesRef.current?.forEach(node => gridApiRef.current.getRowNode(node.id)?.setSelected(true));
          autoSizeColumnsIfNeeded(e);
          onRowDataChanged?.(e);
        }}
        rowData={data}
        onFilterModified={handleOnFilterModified}
        onRowSelected={onRowSelected}
        onCellKeyDown={(e) => {
          const keyPressed = e.event?.key;
          const columnId = e.colDef?.field;

          if (keyPressed === "Enter") {
            onEnter?.(
              e.data,
              columnId,
              e.api.getSelectedNodes().map((obj) => obj.data),
              e.event,
              e.api,
              e.node,
            );
          }

          if (keyPressed.toUpperCase() === "C" && e.event.ctrlKey) {
            if (columnId) {
              navigator.clipboard.writeText(String(e.api.getValue(columnId, e.node)));
            } else {
              navigator.clipboard.writeText(String(e.data[columns[0].id]));
            }
          }

          if (rowSelection) {
            if (keyPressed === " ") {
              // Use Ctrl + Space for toggling selection
              if (e.event.ctrlKey) {
                e.node.setSelected(!e.node.isSelected());
                return;
              } else if (e.event.shiftKey) {
                // Shift + Space is used internally by ag grid for range selection
                return;
              } else {
                e.event.preventDefault();
                e.event.stopImmediatePropagation();
                e.event.stopPropagation();
              }
            }
            if (toggleRowSelectionOnTab && keyPressed === "Tab") {
              e.node.setSelected(!e.node.isSelected());
            }
            if (["ArrowDown", "ArrowUp"].includes(keyPressed) && e.event.shiftKey) {
              const selectedNodes = e.api.getSelectedNodes().sort((a, b) => a.rowIndex < b.rowIndex ? -1 : 1)
              const rowIndex = e.node.rowIndex;
              const lastSelectedNode = selectedNodes[selectedNodes.length - 1];
              const firstSelectedNode = selectedNodes[0];
              const isLastSelectedNodeOrLater = rowIndex >= lastSelectedNode?.rowIndex;
              const isFirstSelectedNodeOrBefore = rowIndex <= firstSelectedNode?.rowIndex;

              let incrementSelection = true;
              if (
                keyPressed === "ArrowUp" && isLastSelectedNodeOrLater ||
                keyPressed === "ArrowDown" && isFirstSelectedNodeOrBefore
              ) {
                incrementSelection = false;
              }
              if (incrementSelection) {
                e.node.setSelected(true);
                const nextNodeIndex = keyPressed === "ArrowDown" ? rowIndex + 1 : rowIndex - 1;
                const nextNode = e.api.getDisplayedRowAtIndex(nextNodeIndex);
                if (nextNode) {
                  nextNode.setSelected(true);
                }
              } else {
                e.node.setSelected(false);
              }
            }
          }
          onCellKeyDown?.({
            data: e.data,
            colId: e.column.colId,
            event: e.event,
            node: e.node,
            api: e.api,
          });
        }}
        {...gridOptions}
      >
        {rowSelection && !hideCheckboxForRowSelection && (
          <AgGridColumn
            suppressSizeToFit
            pinned="left"
            lockPinned
            colId="checkbox"
            width={40}
            checkboxSelection={true}
            headerComponentFramework={() => (
              <Checkbox ref={headerCheckboxRef} onChange={handleChangeHeaderCheckbox} />
            )}
            {...getCellProps(false)}
          />
        )}
        {mapColumnDefsToAgGridColumnDefs(columns, enableSearch, onEnter)}
      </AgGridReact>
    </div>
  );
};

export default DataTable;
